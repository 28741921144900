.footer {
  padding: 24px;
  margin-bottom: 56px;
}

.imgContainer {
  display: flex;
  justify-content: space-evenly;
}
.text {
  font-size: 16px !important;
  border-bottom: 1px solid var(--core-strong-contrast);
  padding-bottom: 4px;
  font-family: "Overpass Light", sans-serif !important;
}

.img {
  height: 100px;
  width: auto;

  background-color: light-dark(transparent, #fffe);
  border-radius: 1em;
  padding: 0.5em;
}
