.burgerMenu {
    cursor: pointer;
    padding: 8px;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 5px;
    background-color: var(--core-primary);
    color: var(--darkest-blue);

    * {
        background-color: inherit;
    }
}

.menuIcon {
    font-size: 20px;
    color: light-dark();
}

.burgerContainer{
    padding: 8px;
}

.alliButton{
        border: none;
        padding: 0px;
        :hover {
            cursor: pointer;
        }
}


.menu {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  nav {
    min-height: calc(100vh - 112px );
    position: relative;
    display: flex;
    align-items: center;
    ul {
        padding: 8px;
        li{
            padding:  20px 8px 20px 8px;
            list-style-type: none;
            button{
                border: none;
                color: light-dark(--core-text);
                font-size: 24px;
                font-family: 'Overpass Bold', sans-serif;
            }
            :hover {
                box-shadow: inset 0px -8px  var(--medium-blue);
                cursor: pointer;
            }

        }
    }
  }
}

.header {
    display: flex;
    flex-direction: row;
    height: 72px;
    justify-content: space-between;
    padding: 8px;
    left: 0;
    top: 0;
    z-index: 1;
    // background-color: white;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
}

.title{
    padding: 8px;
    align-items: center;
    display: flex;
}


@media screen and (min-width: 768px) {
    .header {
        position: fixed;
        z-index: 1;
    }

    .menu {
        nav {
            justify-content: center;
            align-items: center;
            ul {
                text-align: center;
                position: absolute;
                bottom: unset;
                left: unset;
                li{
                    button{
                        font-size: 40px;
                    }
                }
            }
    }
    }
 }
