.contentSection {
    padding: 8px ;
    align-items: center;
    display:flex;
    flex-direction: column;
 > * {
    padding: 8px ;
    margin: 0px;
 }
}

@media screen and (min-width: 768px) {
   .contentSection {
      padding: 24px ;
   }
}

@media screen and (min-width: 1200px) {
   .contentSection {
      padding: 40px ;
   }
}