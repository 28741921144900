.content{
    flex-direction: column;
    justify-content: space-around;
    min-height: calc(100vh - 112px );
    display: flex;
    padding: 8px;
    box-sizing: border-box;
}

.page {
    top: 72px;
    position: absolute;
}

.srOnly {
    position:absolute;
    left:-10000px;
    top: -10000px;
    width:1px;
    height:1px;
    overflow:hidden;
}

@media screen and (min-width: 768px) {
    .content{
        padding-left: 20%;
        padding-right: 20%;
        text-align: center;
        top: 0;
        position: static;
    }
}

@media screen and (min-width: 1200px) {
    .content{
        padding-left: 25%;
        padding-right: 25%;
    }
}