@import "../../styles/global.scss";

.home p{
    font-size: 24px;
    line-height: 30px;
}

.home .img {
    max-width: 100%;
}

.imgContainer {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
    img {
        height: 50px;
    }
}


@media screen and (min-width: 768px) {
    .home p{
         font-size: 28px;
         line-height: 36px;
    }
}

@media screen and (min-width: 1200px) {
    .home p{
         font-size: 32px;
         line-height: 42px;
    }
}
