.banner {
    left: 0;
    bottom: 0;
    position: fixed;
    height: 56px;
    width: 100%;
    padding: 12px 24px 12px 24px;
    background-color: var(--core-primary);
    box-sizing: border-box;
    align-items: center;
    display: flex;
    border-radius: 8px 8px 0 0;
    font-size: 24px;
    font-family: 'Overpass Black', sans-serif;
    color: var(--darkest-blue);
}
