@import "../../styles/global.scss";

.two_columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.imgContainer {
  display: grid;
	gap: 1em;
  justify-items: center;
  img {
    width: 100%;
    max-height: 100px;
  }
}
