.alliTitle {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    vertical-align: bottom;
}

.logo {
    height: 30px;
    width: 22px;
    transform: translateY(0.3rem);
    margin: 0 0.3em;
}

.title {
    font-size: 24px;
    font-family: 'Overpass Bold', sans-serif;
}


@media screen and (min-width: 768px) {
    .alliTitleHome{
        .logo{
            height: 36px;
            width: 28px;
        }
        .title{
            font-size: 28px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .alliTitleHome{
        .logo{
            height: 40px;
            width: 32px;
        }
        .title{
            font-size: 32px;
        }
    }
}
